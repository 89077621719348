import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { profileReducer as Profile } from "./auth/ForgetPassword/ForgetPasswordReducer";
import { loginReducer as LoginUser } from "./auth/Login/LoginReducer";
import Product from "./products/GetAllProducts/ProductReducer";
import { storeReducer as StoreData } from "./store/CreateStoreData/StoreReducer";
import { userReducer as User } from "./User/GetSingleUser/GetSingleUserReducer";

import createSagaMiddleware from 'redux-saga'
const saga = createSagaMiddleware()

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const middleware = [thunk]
const enhancer = composeEnhancers(applyMiddleware(saga, ...middleware))

const store = createStore(combineReducers({
  LoginUser,
  Product,
  StoreData,
  Profile,
  User,
}), enhancer)

export default store
