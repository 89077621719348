import React from 'react'
import './app.css'

import Routes from '../routes/Routes'

export default function App() {
  return (
    <div className={'ltr'} dir={'ltr'}>
      <Routes />
    </div>
  )
}
