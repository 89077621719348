import React, { useEffect, useState } from "react";
import "./orderList.css";
import { DataGrid } from "@material-ui/data-grid";
import { Btn } from "../../components/Controls/Button/Button";
import { Link, useHistory } from "react-router-dom";
import checkText from "../../assets/images/GetRate.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../store/store/CreateStoreData/StoreActions";
import moment from "moment";
import Loader from "../../components/Loader/Loader";

function OrdersList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const { orders } = useSelector((state) => state.StoreData);
  const [currentOrders, setCurrentOrders] = useState();
  const [select, setSelection] = useState([]);

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    try {
      setCurrentOrders(orders);
    } catch (error) {}
  }, [orders]);

  const handleSearch = () => {
    let name;
    const allOrders = [...orders];
    let filter = document.getElementById("example-search-input").value;

    name = filter;
    if (name === "") {
      setCurrentOrders(orders);
    }
    const lc = (i) => (i ? i.toString().toLowerCase() : "");
    const newOrders = allOrders.filter((order) =>
      lc(order?.billing_address?.name).includes(lc(name))
    );

    setCurrentOrders(newOrders);
  };

  const fetchOrder = async () => {
    try {
      setLoader(true);
      await dispatch(getAllOrders());
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handleGetRate = async () => {
    if (select.length > 0) {
      const orderIds = select.join(",");
      return history.push(`/orders-rate?orderIds=${orderIds}`);
    }
  };

  return (
    <>
      <main className="content">
        <div className="d-flex justify-content-between w-75 my-4">
          <div className="sbmt-btn mx-2">
            <Btn handleClick={() => fetchOrder()}>Refresh Data</Btn>
          </div>
          <div className="sbmt-btn mx-2">
            <Btn handleClick={() => handleGetRate()} btnClass="outline-border">
              Get Rate
            </Btn>
          </div>

          <div className="container">
            <div className="row pt-1 ">
              <div className="input-group col-md-4">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter keyword to search"
                  id="example-search-input"
                  style={{ height: "80%", background: "white" }}
                />

                <button
                  className="btn "
                  onClick={handleSearch}
                  type="button"
                  style={{ height: "80%", background: "#DEDEDE" }}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <div id="table" style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={
                currentOrders?.map((order, index) => {
                  return {
                    id: order.id,
                    Actions: "",
                    name: `${order?.billing_address?.name ?? "-"}`,
                    source: `${`shopify`}`,
                    orderDate: `${moment(`${order?.created_at}`, [
                      "YYYY-MM-DD",
                      "h:mm:ssZZ",
                    ]).format("Do MMMM YYYY, h:mm a")}`,
                    orderDetail: `${order?.line_items[0].name}`,
                    Address: `${order?.billing_address?.address1 ?? "-"}`,
                    Note: `${order?.note ?? "-"}`,
                    orderId: `${order?.order_number ?? "-"}`,
                    FinancialStatus: `${order?.billing_address?.phone ?? "-"}`,
                    Phone: `${order?.billing_address?.phone ?? "-"}`,
                  };
                }) ?? []
              }
              columns={[
                {
                  field: "Actions",
                  headerName: "Actions",
                  width: 130,
                  renderCell: (cell) => (
                    <Link to={`/orders-rate?orderIds=${cell.id}`}>
                      <img src={checkText} />
                    </Link>
                  ),
                },
                {
                  field: "name",
                  headerName: "Name",
                  width: 130,
                },
                {
                  field: "source",
                  headerName: "Source",
                  width: 130,
                  editable: true,
                },
                {
                  field: "orderDate",
                  headerName: "Order Date",
                  width: 150,
                },
                {
                  field: "orderDetail",
                  headerName: "Order Detail",
                  width: 200,
                },
                {
                  field: "Address",
                  headerName: "Address",
                  width: 220,
                },
                {
                  field: "Note",
                  headerName: "Note",
                  width: 50,
                },
                {
                  field: "orderId",
                  headerName: "ORDER ID",
                  type: "number",
                  width: 140,
                },
                {
                  field: "FinancialStatus",
                  headerName: "Financial Status",
                  width: 180,
                },
                {
                  field: "Phone",
                  headerName: "Phone",
                  width: 180,
                },
              ]}
              pageSize={10}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(newSelectionArray) => {
                setSelection(newSelectionArray);
              }}
            />
          </div>
        )}
      </main>
    </>
  );
}

export default OrdersList;
