import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './style.css'
// import {
//   createStoreData,
//   deleteStore,
// } from '../../store/storeData/CreateStoreData/StoreActions'
import retry from '../../assets/images/retry.svg'
import { createStoreData } from '../../store/store/CreateStoreData/StoreActions'
import logo from '../../assets/images/logo.png'
import * as yup from 'yup'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Btn } from '../../components/Controls/Button/Button'
import Loader from '../../components/Loader/Loader'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { userConfig as config } from '../../utils/Constants'
import { toast } from 'react-toastify'

function Dashboard() {
  const dispatch = useDispatch()
  const [stores, setAllStores] = useState([])
  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false)
  const [storeId, setStoreId] = useState(null)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    fetchStore()
  }, [])

  const fetchStore = async () => {
    try {
      setLoader(true)
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/store`,
      )
      setAllStores(data)
    } catch (error) {
    } finally {
      setLoader(false)
    }
  }

  const schema = yup.object().shape({
    url: yup
      .string()
      .url('Must be a valid url')
      .matches(
        /(myshopify.com)/,
        'The url you provided is not a shopify store url',
      )
      .required(),
  })
  const handleAddNewStoreSubmit = async (url) => {
    try {
      setLoader(true)
      const urlObj = new URL(url)
      await dispatch(createStoreData(urlObj?.host))
      await fetchStore()
    } catch (error) {
    } finally {
      setLoader(false)
    }
  }

  const handleRemoveStore = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/store/?id=${storeId}`,
      )
      setShow(false)
      await fetchStore()

      toast.success('Store Deleted Successfully!')
    } catch (error) {
      toast.danger('Unable to delete store')
    }
  }

  const handleRetry = async (data) => {
    try {
      const url = `https://${data}/`

      await handleAddNewStoreSubmit(url)
    } catch (error) {}
  }
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <main className="content">
          <div className="ordermix-content">
            <div className="ordermix-top-wraper">
              <div className="ordermix-icon text-center">
                <img src={logo} />
              </div>
              <Formik
                validationSchema={schema}
                initialValues={{ url: '' }}
                onSubmit={(values) => {
                  handleAddNewStoreSubmit(values.url)
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div className="ordermix-form">
                      <div className="form-field">
                        <label className="domain" htmlFor="domain">
                          Shopify Domain
                        </label>
                        <br />
                        <div className="domain-field">
                          <Field
                            className={
                              errors.url ? 'domain-in is-invalid' : 'domain'
                            }
                            type="text"
                            placeholder="example.myshopify.com"
                            name="url"
                            required=""
                          />
                          <ErrorMessage name="url">
                            {(msg) => (
                              <div>
                                {msg ? (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    {msg}
                                  </p>
                                ) : null}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="order-error invalid">
                          <i className="fas fa-exclamation-circle"></i>
                          Must be your myshopify.com domain even if you are
                          using a custom domain
                        </div>
                      </div>

                      <div className="submit-btn">
                        <Btn icon={<i className="fas fa-angle-right"></i>}>
                          Add Store
                        </Btn>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="ordermix-table">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Shopify url</th>
                    <th>Status</th>
                    <th></th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {stores?.length > 0 ? (
                    stores.map((data, index) => (
                      <tr
                        className={index % 2 === 0 ? 'even' : 'odd'}
                        key={index}
                      >
                        <td>{++index}</td>
                        <td>{data?.url}</td>
                        <td className="d-flex">
                          {data.token ? 'Connnected' : 'Not connected'}
                        </td>

                        <td className="retry-img">
                          {!data.token ? (
                            <a
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Retry"
                              onClick={() => handleRetry(data?.url)}
                            >
                              <img src={retry} alt="retry svg" />{' '}
                            </a>
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          <p>
                            <a
                              className="remove"
                              onClick={() => {
                                handleShow()
                                setStoreId(data.id)
                              }}
                            >
                              Remove
                            </a>
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="even">
                      <td>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* <!-- Modal --> */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header custom">
                      <div className="modal-del">
                        <i className="fas fa-trash-alt"></i>
                      </div>
                      <h3 className="modal-title" id="exampleModalLabel">
                        You Are About to Delete This Store.
                      </h3>
                      <p>
                        When you delete this store. The orter will be removed.
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn delete-btn"
                  onClick={handleRemoveStore}
                >
                  Delete
                </button>
              </Modal.Footer>
            </Modal>

            {/* <!-- Modal close ---> */}
          </div>
        </main>
      )}
    </>
  )
}

export default Dashboard
