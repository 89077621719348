import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { signup} from '../../store/auth/Login/LoginAction'
import img from '../../assets/images/content.png'
import logo from '../../assets/images/logo.png'
import { Btn } from '../../components/Controls/Button/Button'
import { notificationMessages } from '../../utils/Constants'

function Signup() {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [passwordType, setPasswordType] = useState('password')

  const validationSchema = yup.object({
    firstname: yup.string().required('First Name is required'),
    lastname: yup.string().required('Last Name is required'),
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required'),
  })

  const handleSignupSubmit = async (values) => {
    try {
      setLoader(true)
      await dispatch(signup(values))
    } catch (error) {
    } finally {
      setLoader(false)
    }
  }

  const viewPassword = () => {
    if (passwordType === 'text') {
      setPasswordType('password')
    } else {
      setPasswordType('text')
    }
  }
  return (
    <>
      <div className="login-body">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            password: '',
          }}
          onSubmit={(values) => {
            handleSignupSubmit(values)
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <section className="login-first-section">
                <div className="container">
                  <div className="cstm-logo">
                  <Link to="/login"> <img src={logo}  /></Link>
                  </div>
                  <div className="login-top-img">
                    <img src={img} alt="image" />
                  </div>
                  <div className="row login-rw">
                    <div className="login-form error create-account">
                      <h2 className="main-heading">Create Account</h2>
                      <p>Sign Up with your Email for Registration.</p>
                      <div className="login-error-form">
                        <div className="login-form-er">
                          <div className="create-half">
                            <div className="form-field">
                              <label className="fname" htmlFor="fname">
                                First Name
                              </label>
                              <br />
                              <div className="icon-field">
                                <Field
                                  className={`fname ${
                                    errors.firstname && touched.firstname
                                      ? ' is-invalid'
                                      : ''
                                  }`}
                                  type="text"
                                  placeholder="First Name"
                                  name="firstname"
                                  required=""
                                  autoComplete="on"
                                />
                                <i className="far fa-user"></i>
                              </div>
                              <ErrorMessage name="firstname">
                                {(msg) => (
                                  <div>
                                    {msg ? (
                                      <p className="login-error invalid">
                                        <i className="fas fa-exclamation-circle"></i>
                                        {msg}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="form-field">
                              <label className="lname" htmlFor="lname">
                                Last Name
                              </label>
                              <br />
                              <div className="icon-field">
                                <Field
                                  className={`lname ${
                                    errors.lastname && touched.lastname
                                      ? ' is-invalid'
                                      : ''
                                  }`}
                                  type="text"
                                  placeholder="Last Name"
                                  name="lastname"
                                  required=""
                                  autoComplete="on"
                                />
                                <i className="far fa-user"></i>
                              </div>
                              <ErrorMessage name="lastname">
                                {(msg) => (
                                  <div>
                                    {msg ? (
                                      <p className="login-error invalid">
                                        <i className="fas fa-exclamation-circle"></i>{' '}
                                        {msg}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="form-field">
                            <label className="email" htmlFor="umail">
                              Email Address
                            </label>
                            <br />
                            <div className="icon-field">
                              <Field
                                className={`fname ${
                                  errors.email && touched.email
                                    ? ' is-invalid'
                                    : ''
                                }`}
                                type="text"
                                placeholder="Email Address"
                                name="email"
                                required=""
                                autoComplete="on"
                              />
                              <i className="far fa-envelope"></i>
                            </div>
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div>
                                  {msg ? (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>{' '}
                                      {msg}
                                    </p>
                                  ) : (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>{' '}
                                      Oops! The email you have entered is
                                      incorrect.
                                    </p>
                                  )}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="form-field">
                            <label className="pass" htmlFor="pass">
                              Password
                            </label>
                            <br />
                            <div className="icon-field">
                              <Field
                                className={`fname ${
                                  errors.password && touched.password
                                    ? ' is-invalid'
                                    : ''
                                }`}
                                type={passwordType}
                                placeholder="Password"
                                name="password"
                                required=""
                                autoComplete="on"
                              />
                              <i className="fas fa-lock"></i>
                              <div className="eye-icon">
                                <i
                                  onClick={viewPassword}
                                  className="far fa-eye"
                                ></i>
                              </div>
                            </div>

                            <ErrorMessage name="password">
                              {(msg) => (
                                <div>
                                  {msg ? (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>
                                      {msg}
                                    </p>
                                  ) : (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>
                                      The password you have entered is incorrect
                                    </p>
                                  )}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="submit-btn">
                            <Btn
                              icon={<i className="fas fa-angle-right"></i>}
                              load={loader}
                            >
                              Sign Up
                            </Btn>
                          </div>
                          <p className="text-center mb-0 mt-3">Or Login with</p>
                          <p className="text-center mb-0 ">
                            Already have an account?
                            <Link to="/login" className="register-btn">
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="login-right-img">
                    <img src={img} />
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default Signup
