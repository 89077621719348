import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { logout } from '../../store/auth/Logout/LogoutAction'
import { getSingleUser } from '../../store/User/GetSingleUser/GetSingleUserAction'
import { routes } from '../../utils/Constants'
function Header({ children }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { detail } = useSelector((state) => state.User)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      await dispatch(getSingleUser())
    } catch (error) {}
  }

  function caps(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const logoutSubmit = async () => {
    try {
      await dispatch(logout())
      history.push('/login')
    } catch (error) {}
  }

  return (
    <>
      <div className="layout header-bar">
        <header className="header">
          <a id="btn-collapse" href="#">
            <i className="ri-menu-line ri-xl"></i>
          </a>
          <a
            id="btn-toggle"
            href="#"
            className="sidebar-toggler break-point-lg"
          >
            <i className="ri-menu-line ri-xl"></i>
          </a>
          <div className="dropdown">
            <a
              className="btn dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {detail
                ? `${caps(detail?.firstname ?? '')} ${caps(
                    detail?.lastname ?? '',
                  )}`
                : 'Guest'}
            </a>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <Link className="dropdown-item" to={`${routes.USER_PROFILE}`}>
                  Profile
                </Link>
              </li>
              <li className="dropdown-item" onClick={logoutSubmit}>
                Logout
              </li>
            </ul>
          </div>
        </header>
        {children}
      </div>
    </>
  )
}

export default Header
