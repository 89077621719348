import axios from 'axios'
import { toast } from 'react-toastify'
import { userConfig as config } from '../../../utils/Constants'
import {
  PRINT_LABEL_FAIL,
  PRINT_LABEL_SUCCESS,
} from '../PrintLabel/PrintLabelType'
import {
  SAVE_USER_SHIPMENT_DETAILS_FAIL,
  SAVE_USER_SHIPMENT_DETAILS_SUCCESS,
} from '../SaveUserShippmentDetails/SaveUserShipmentDetailsType'

import {
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAIL,
} from './GetSingleUserType'

export const getSingleUser = () => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/users/user`
    const { data } = await axios.get(url)
    dispatch({ type: GET_SINGLE_USER_SUCCESS, payload: data })

  } catch (error) {
    console.log('eror=>',error)
    dispatch({ type: GET_SINGLE_USER_FAIL, message: 'User not found' })
  }
}

export const setShipmentDetail = (data) => (dispatch) => {
  dispatch({ type: SAVE_USER_SHIPMENT_DETAILS_SUCCESS, payload: data })
}

export const saveUserShipmentDetails = (userData) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/users/shipment_details`
    const { data } = await axios.post(url, userData)
    dispatch({ type: SAVE_USER_SHIPMENT_DETAILS_SUCCESS, payload: data?.data })
  } catch (error) {
    if (error.response.data.message === 'Invalid to_postal_code.') {
      toast.error('Invalid postal code')
      return dispatch({
        type: SAVE_USER_SHIPMENT_DETAILS_FAIL,
        message: 'Invalid postal code',
      })
    }

    return dispatch({
      type: SAVE_USER_SHIPMENT_DETAILS_FAIL,
      message: error.response.data.message,
    })

  }
}

export const printLabel = (data, detailId) => async (dispatch) => {
  const { service_code } = data
  try {
    const orderId = localStorage.getItem('select')
    const splitedId = orderId.split(',')

    const userData = {
      splitedId,
      label_format: 'png',
      label_layout: '4x6',
      label_download_type: 'inline',
      shipment: {
        service_code,
        ship_from: {
          name: 'John Doe',
          company_name: 'Example Corp.',
          address_line1: '4009 Marathon Blvd',
          city_locality: 'Austin',
          state_province: 'TX',
          postal_code: '78756',
          country_code: 'US',
          phone: '512-555-5555',
        },
        ship_to: {
          name: 'Amanda Miller',
          address_line1: '525 S Winchester Blvd',
          city_locality: 'San Jose',
          state_province: 'CA',
          postal_code: '95128',
          country_code: 'US',
        },
        packages: [
          {
            weight: {
              value: 17,
              unit: 'pound',
            },
            dimensions: {
              length: 36,
              width: 12,
              height: 24,
              unit: 'inch',
            },
          },
        ],
      },
    }
    const url = `${process.env.REACT_APP_BASE_URL}/v1/store/print/${detailId}`
    const { data } = await axios.post(url, userData)
    dispatch({ type: PRINT_LABEL_SUCCESS, payload: data?.data })
  } catch (error) {
    console.log({
      message: error.message,
      stack: error.stack
    });
    dispatch({ type: PRINT_LABEL_FAIL, payload: 'Unable to get label' })
  }
}
