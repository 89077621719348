import React, { useEffect, useState } from "react";
import "./ordersRate.css";
import box from "../../assets/images/box.png";
import envelop from "../../assets/images/envelop.png";
import ups from "../../assets/images/ups.png";
import rateBox from "../../assets/images/rateBox.png";
import Loader from "../../components/Loader/Loader";
import { useHistory, useLocation } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  getSingleUser,
  setShipmentDetail,
} from "../../store/User/GetSingleUser/GetSingleUserAction";
import { userConfig } from "../../utils/Constants";
import axios from "axios";
import { toast } from "react-toastify";
import { getAllOrders } from "../../store/store/CreateStoreData/StoreActions";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    summary: {
      background: "linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%)",
      border: "1px solid #C7C7C7",
    },
    details: {
      background: "#fff",
      border: "1px solid #C7C7C7",
    },
  })
);

const boxes = [
  {
    id: 1,
    title: "Box or Rigid Packaging",
    description: "Any custom box or thick parcel",
    image: box,
    active: true,
    PackageType: "box",
  },
  {
    id: 2,
    title: "Envelope, Padded Envelope, Poly Bag, Soft Pack, or Box in a Bag",
    description:
      "Measure & use the Length and Width of the Envelope before putting anything in it",
    image: envelop,
    active: false,
    PackageType: "envelope",
  },
  {
    id: 3,
    title: "USPS Priority Mail Small Flat Rate Box",
    description: "Small Flat Rate Mailing Box only",
    image: ups,
    active: false,
    PackageType: "small-Rate-Box",
  },
  {
    id: 4,
    title: "USPS Priority Mail Medium Flat Rate Box",
    description:
      "Any Medium Flat Rate Box, including 1 (Top-Loading) and 2 (Side-Loading)",
    image: rateBox,
    active: false,
    PackageType: "medium-rate-box",
  },
];

const Orders = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.StoreData);
  const [activeBoxId, setActiveBoxId] = useState(1);
  const [packageDetails, setPackageDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { search } = useLocation();
  const url = new URLSearchParams(search);
  const orderIds = url.get("orderIds");
  const getBoxFromId = (id) => boxes.find((b) => b.id === id);
  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState({
    packageLength: "",
    packageWidth: "",
    packageHeight: "",
    packageWeight: "",
    senderAddress: "",
    senderCountry: "US",
    senderZipCode: "",
    receiverAddress: "",
    receiverCountry: "US",
    receiverZipCode: "",
  });

  const restBoxes = boxes.filter((b) => b.id !== activeBoxId);

  useEffect(async () => {
    let weight, receiverAddress, receiverZip;
    if (!orderIds) {
      return history.push("/no-order-selected");
    }
    
    let oIds = orderIds.split(",");
    if (orders && orders.length) {
      setLoading(true);

      const matchingOrders = orders.filter((o) =>
        oIds.includes(o.id.toString())
      );
      weight = matchingOrders.reduce((prev, curr) => {
        return prev + curr.line_items.reduce((pre, cur) => pre + cur.grams, 0);
      }, 0);
      weight = weight > 0 ? Math.floor(weight / 453) : 0; //Changing grams to lbs(pounds);

      //Find an order with shipping address
      const shippingOrder = matchingOrders.find((o) => o.shipping_address);
      if (shippingOrder) {
        receiverAddress = shippingOrder.shipping_address?.address1 ?? "";
        receiverZip = shippingOrder.shipping_address?.zip ?? "";
      }
      const initial = { ...initialValue };
      if (weight) initial.packageWeight = weight;
      if (receiverAddress) initial.receiverAddress = receiverAddress;
      if (receiverZip) initial.receiverZipCode = receiverZip;
      setInitialValue(initial);
      setTimeout(()=>setLoading(false));
    }
  }, [orders]);

  useEffect(() => {
    dispatch(getAllOrders());
  }, []);

  const validationSchema = yup.object({
    packageLength: yup
      .number()
      .required("Length is required")
      .test(
        "Is positive?",
        "ERROR: The number must be greater than 0!",
        (value) => value > 0
      ),
    packageWidth: yup
      .number()
      .required("Width is required")
      .test(
        "Is positive?",
        "ERROR: The number must be greater than 0!",
        (value) => value > 0
      ),
    packageHeight: yup
      .number()
      .required("Height is required")
      .test(
        "Is positive?",
        "ERROR: The number must be greater than 0!",
        (value) => value > 0
      ),
    packageWeight: yup
      .number()
      .required("Weight is required")
      .test(
        "Is positive?",
        "ERROR: The number must be greater than 0!",
        (value) => value > 0
      ),
    senderAddress: yup.string().required("Address is required"),
    // senderCountry: yup.string().required('Country name is required'),
    senderZipCode: yup.string().required("Zip code is required"),
    receiverAddress: yup.string().required("Address is required"),
    // receiverCountry: yup.string().required('Country name is required'),
    receiverZipCode: yup.string().required("Zip code is required"),
  });

  const getRate = async (allValues) => {
    try {
      const {
        packageLength,
        packageWidth,
        packageHeight,
        packageWeight,
        receiverAddress,
        receiverCountry,
        receiverZipCode,
        senderAddress,
        senderCountry,
        senderZipCode,
      } = allValues;
      setLoader(true);
      const data = {
        carrier_ids: ["se-3280658"],
        from_country_code: receiverCountry,
        from_postal_code: receiverZipCode,
        from_city_locality: receiverAddress,
        // from_state_province: 'TX',
        to_country_code: senderCountry,
        to_postal_code: senderZipCode,
        to_city_locality: senderAddress,
        // to_state_province: 'CA',

        weight: {
          value: packageWeight,
          unit: "pound",
        },
        dimensions: {
          length: packageLength,
          width: packageWidth,
          height: packageHeight,
          unit: "inch",
        },
        packageType: getBoxFromId(activeBoxId)?.PackageType,
        packageDetails,
      };

      const url = `${process.env.REACT_APP_BASE_URL}/v1/users/shipment_details`;
      const { data: result } = await axios.post(url, data);
      if (result.data?.id) {
        history.push(`/rate/${result.data?.id}`);
      }
      dispatch(setShipmentDetail(result.data));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Internal server error");
    } finally {
      await dispatch(getSingleUser());
      setLoader(false);
    }
  };

  return (
    <>
      <main className="content">
        <div className="order-ship-main" id="orders-ship-wrapper">
          {!loading && (
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValue}
              onSubmit={(values) => {
                getRate(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  {loader ? (
                    <Loader />
                  ) : (
                    <div className={classes.root}>
                      <Typography className={classes.heading}>
                        <span className=" h5 text-dark ship-title top">
                          Shipping details
                        </span>
                      </Typography>
                      <Accordion>
                        <AccordionSummary
                          className={classes.summary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          {initialValue?.receiverAddress ? (
                            <Typography className={classes.heading}>
                              <h3 className="ship-title">{initialValue?.receiverAddress}</h3>
                            </Typography>
                          ) : (
                            <Typography className={classes.heading}>
                              <h3 className="ship-title">
                                Please provide the Receiver Information
                              </h3>
                            </Typography>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col ">
                                <div className="card">
                                  <div className="card-header py-3">
                                    <h5 className="mb-0">Sender Info</h5>
                                  </div>
                                  <div className="card-body">
                                    {/* <!-- Text input --> */}

                                    <div className="form-outline">
                                      <label
                                        className="form-label"
                                        htmlFor="form7Example4"
                                      >
                                        Address
                                      </label>
                                      <Field
                                        type="text"
                                        id="form7Example4"
                                        className={` ${
                                          errors.senderAddress
                                            ? "is-invalid"
                                            : "form-control"
                                        }`}
                                        name="senderAddress"
                                        placeholder="Street Address"
                                      />

                                      <ErrorMessage name="senderAddress">
                                        {(msg) => (
                                          <div>
                                            {msg ? (
                                              <p className="login-error invalid">
                                                <i className="fas fa-exclamation-circle"></i>
                                                {msg}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    {/* <!-- 2 column grid layout with text inputs htmlFor the first and last names --> */}
                                    <div className="row mb-4">
                                      <div className="col">
                                        <div className="form-outline">
                                          <label
                                            className="form-label"
                                            htmlFor="form7Example1"
                                          >
                                            Country
                                          </label>
                                          <Field
                                            as="select"
                                            id="form7Example1"
                                            className="form-control"
                                            name="senderCountry"
                                          >
                                            <option value="US">US</option>
                                          </Field>
                                          {/* <select
                                        id="form7Example1"
                                        className="form-control"
                                        name="senderCountry"
                                      >
                                        <option value="US">US</option>
                                      </select> */}

                                          <ErrorMessage name="senderCountry">
                                            {(msg) => (
                                              <div>
                                                {msg ? (
                                                  <p className="login-error invalid">
                                                    <i className="fas fa-exclamation-circle"></i>
                                                    {msg}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="form-outline">
                                          <label
                                            className="form-label"
                                            htmlFor="form7Example2"
                                          >
                                            Zip code
                                          </label>
                                          <Field
                                            type="number"
                                            id="form7Example2"
                                            className={` ${
                                              errors.senderZipCode &&
                                              touched.senderZipCode
                                                ? "is-invalid"
                                                : "form-control"
                                            }`}
                                            name="senderZipCode"
                                            placeholder="Zip / Postal Code"
                                          />

                                          <ErrorMessage name="senderZipCode">
                                            {(msg) => (
                                              <div>
                                                {msg ? (
                                                  <p className="login-error invalid">
                                                    <i className="fas fa-exclamation-circle"></i>
                                                    {msg}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header py-3">
                                    <h5 className="mb-0">Receiver Info</h5>
                                  </div>
                                  <div className="card-body">
                                    {/* <!-- Text input --> */}
                                    <div className="form-outline">
                                      <label
                                        className="form-label"
                                        htmlFor="form7Example4"
                                      >
                                        Address
                                      </label>
                                      <Field
                                        type="text"
                                        id="form7Example4"
                                        className={` ${
                                          errors.receiverAddress &&
                                          touched.receiverAddress
                                            ? "is-invalid"
                                            : "form-control"
                                        }`}
                                        name="receiverAddress"
                                        placeholder="Street Address"
                                      />
                                      <ErrorMessage name="receiverAddress">
                                        {(msg) => (
                                          <div>
                                            {msg ? (
                                              <p className="login-error invalid">
                                                <i className="fas fa-exclamation-circle"></i>
                                                {msg}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    {/* <!-- 2 column grid layout with text inputs htmlFor the first and last names --> */}
                                    <div className="row mb-4">
                                      <div className="col">
                                        <div className="form-outline">
                                          <label
                                            className="form-label"
                                            htmlFor="form7Example1"
                                          >
                                            Country
                                          </label>
                                          <Field
                                            component="select"
                                            id="form7Example1"
                                            className="form-control"
                                            name="receiverCountry"
                                          >
                                            <option value="US">US</option>
                                          </Field>

                                          <ErrorMessage name="receiverCountry">
                                            {(msg) => (
                                              <div>
                                                {msg ? (
                                                  <p className="login-error invalid">
                                                    <i className="fas fa-exclamation-circle"></i>
                                                    {msg}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="form-outline">
                                          <label
                                            className="form-label"
                                            htmlFor="form7Example2"
                                          >
                                            Zip code
                                          </label>
                                          <Field
                                            type="number"
                                            id="form7Example2"
                                            className={` ${
                                              errors.receiverZipCode &&
                                              touched.receiverZipCode
                                                ? "is-invalid"
                                                : "form-control"
                                            }`}
                                            name="receiverZipCode"
                                            placeholder="Zip / Postal Code"
                                          />
                                          <ErrorMessage name="receiverZipCode">
                                            {(msg) => (
                                              <div>
                                                {msg ? (
                                                  <p className="login-error invalid">
                                                    <i className="fas fa-exclamation-circle"></i>
                                                    {msg}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Typography className={classes.heading}>
                        <h3 className="ship-title top">Package Details</h3>
                      </Typography>
                      <Accordion>
                        <AccordionSummary
                          className={classes.summary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            <h3 className="ship-title">
                              Dont use Saved Package
                            </h3>
                            <p className="ship-tx">
                              Enter package details manually
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlTextarea1">
                                Package Details
                              </label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="2"
                                cols="100"
                                value={packageDetails}
                                onChange={(e) =>
                                  setPackageDetails(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Typography className={classes.heading}>
                        <h3 className="ship-title top">Type of Packaging</h3>
                      </Typography>
                      <Accordion expanded={expanded}>
                        <AccordionSummary
                          className={classes.summary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => setExpanded(!expanded)}
                        >
                          {activeBoxId && (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ marginRight: "20px" }}>
                                <img src={getBoxFromId(activeBoxId).image} />
                              </div>
                              <Typography className={classes.heading}>
                                <h3 className="ship-title">
                                  {getBoxFromId(activeBoxId).title}
                                </h3>
                                <p className="ship-tx">
                                  {getBoxFromId(activeBoxId).description}
                                </p>
                              </Typography>
                            </Box>
                          )}
                        </AccordionSummary>

                        <div style={{ marginTop: "13px" }}>
                          {restBoxes.map((box) => (
                            <AccordionDetails
                              id="envelope"
                              className={classes.details}
                              onClick={() => setExpanded(false)}
                            >
                              <Box
                                className="pointer"
                                onClick={() => setActiveBoxId(box.id)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ marginRight: "20px" }}>
                                  <img src={box.image} />
                                </div>
                                <Typography className={classes.heading}>
                                  <h3 className="ship-title">{box.title}</h3>
                                  <p className="ship-tx">{box.description}</p>
                                </Typography>
                              </Box>
                            </AccordionDetails>
                          ))}
                        </div>
                      </Accordion>
                      {/* <form className={classes.root} noValidate autoComplete="off */}
                      <Typography className={classes.heading}>
                        <h3 className="ship-title top">Package Details</h3>
                      </Typography>
                      <Box
                        className="pointer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="form-field">
                          <Field
                            className={`${
                              errors.packageLength && touched.packageLength
                                ? "is-invalid"
                                : ""
                            }`}
                            id="outlined-basic"
                            label="Length"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="number"
                            name="packageLength"
                            placeholder="Length"
                            // onChange={changeHandler}
                          />

                          <ErrorMessage name="packageLength">
                            {(msg) => (
                              <>
                                {msg ? (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    {msg}
                                  </p>
                                ) : (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    Oops! The email you have entered is
                                    incorrect.
                                  </p>
                                )}
                              </>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="form-icon">
                          <CloseIcon />
                        </div>
                        <div className="form-field">
                          <Field
                            className={`${
                              errors.packageWidth && touched.packageWidth
                                ? "is-invalid"
                                : ""
                            }`}
                            id="outlined-basic"
                            label="Width"
                            variant="outlined"
                            name="packageWidth"
                            type="number"
                            // onChange={changeHandler}
                            style={{ width: "100%" }}
                            placeholder="Width"
                          />
                          <ErrorMessage name="packageWidth">
                            {(msg) => (
                              <>
                                {msg ? (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    {msg}
                                  </p>
                                ) : (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    Oops! The email you have entered is
                                    incorrect.
                                  </p>
                                )}
                              </>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="form-icon">
                          <CloseIcon />
                        </div>
                        <div className="form-field">
                          <Field
                            className={`${
                              errors.packageHeight && touched.packageHeight
                                ? "is-invalid"
                                : ""
                            }`}
                            id="outlined-basic"
                            label="Height"
                            variant="outlined"
                            name="packageHeight"
                            type="number"
                            // onChange={changeHandler}
                            style={{ width: "100%" }}
                            placeholder="Height"
                          />
                          <ErrorMessage name="packageHeight">
                            {(msg) => (
                              <div>
                                {msg ? (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    {msg}
                                  </p>
                                ) : (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    Oops! The email you have entered is
                                    incorrect.
                                  </p>
                                )}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </Box>

                      <Typography className={classes.heading}>
                        <h3 className="ship-title top"> OR </h3>
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexFlow: "row wrap",
                        }}
                      >
                        <div className="form-field">
                          <Field
                            className={`${
                              errors.packageWeight && touched.packageWeight
                                ? "is-invalid"
                                : ""
                            }`}
                            id="outlined-basic"
                            label="Weight"
                            variant="outlined"
                            name="packageWeight"
                            type="number"
                            // onChange={changeHandler}
                            style={{ width: "100%" }}
                            placeholder="Weight"
                          />
                          <ErrorMessage name="packageWeight">
                            {(msg) => (
                              <div>
                                {msg ? (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    {msg}
                                  </p>
                                ) : (
                                  <p className="login-error invalid">
                                    <i className="fas fa-exclamation-circle"></i>
                                    Oops! The email you have entered is
                                    incorrect.
                                  </p>
                                )}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </Box>
                      <div className="ship-btn">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            background: " #806BE5",
                            borderRadius: "10px",
                            padding: "10px 30px",
                            color: "#fff",
                          }}
                        >
                          Get Rate
                        </Button>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </div>
      </main>
    </>
  );
};

export default Orders;
