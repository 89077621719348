export const CREATE_STORE_DATA_SUCCESS = 'CREATE_STORE_DATA_SUCCESS'
export const CREATE_STORE_DATA_FAIL = 'CREATE_STORE_DATA_FAIL'

export const GET_ALL_STORE_DATA_SUCCESS = 'GET_ALL_STORE_DATA_SUCCESS'
export const GET_ALL_STORE_DATA_FAIL = 'GET_ALL_STORE_DATA_FAIL'

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ALL_ORDERS_FAIL = 'GET_ALL_ORDERS_FAIL'

export const GET_ALL_SELECTED_ORDERS = 'GET_ALL_SELECTED_ORDERS'
export const GET_ALL_SELECTED_ORDERS_FAIL = 'GET_ALL_SELECTED_ORDERS_FAIL'

export const GET_ALL_ORDERS_RATES_SUCCESS = "GET_ALL_ORDERS_RATES_SUCCESS"
export const GET_ALL_ORDERS_RATES_FAIL = "GET_ALL_ORDERS_RATES_FAIL"

