import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import app from '../../../fire'
import { SIGN_UP_FAIL, SIGN_UP_SUCCESS } from '../Signup/SignupType'
import { LOGIN_SUCCESS, LOGIN_FAIL, VERIFY_MAIL_SUCCESS } from './LoginType'
import axios from 'axios'
import { toast } from 'react-toastify'
import { notificationMessages } from '../../../utils/Constants'

export const login = (data) => async (dispatch) => {
  try {
    const { email, password } = data
    const auth = getAuth(app)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        if (!user.emailVerified) {
          toast.info('Your email is not verified')
          auth().signOut()
        }
        toast.success('Login Successfull', {
          autoClose: 500,
        })
        dispatch({ type: LOGIN_SUCCESS })
      })
      .catch((error) => {
        toast.error(notificationMessages[error.code])
        dispatch({ type: LOGIN_FAIL, payload: error.code })
      })
  } catch (error) {
    toast.error('User not found')
    dispatch({ type: LOGIN_FAIL, payload: error })
  }
}

export const signup = (data) => async (dispatch) => {
  try {
    const { firstname, lastname, email, password } = data
    const auth = getAuth(app)

    //create user in firebase
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    )

    if (userCredential) {
      const { email, uid } = userCredential.user
      //create user in DB
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const axiosData = {
        firstname,
        lastname,
        email,
        uid,
      }
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/users`,
        axiosData,
        config,
      )
      dispatch({ type: SIGN_UP_SUCCESS, payload: 'User created' })
    }

    //verification link is send to the mail
    await sendEmailVerification(userCredential.user)
    toast.success('Verification link is send to your mail', {
      autoClose: 500,
    })
    dispatch({ type: VERIFY_MAIL_SUCCESS, payload: 'Mail is verified' })
  } catch (error) {
    //case handle if user is created in firebase
    const auth = getAuth(app)
    if (error.code === 'auth/email-already-in-use') {
      // const userData = await signInWithEmailAndPassword(auth, email, password)
      // console.log('userData=>', userData)
      // const { uid } = userData.user

      //create user in DB
      // const config = {
      //   headers: {
      //     'Content-type': 'application/json',
      //   },
      // }

      // const axiosData = {
      //   firstname,
      //   lastname,
      //   email,
      //   uid,
      // }
      // console.log(2)
      // try {
      //   await axios.post(
      //     `${process.env.REACT_APP_BASE_URL}/v1/users`,
      //     axiosData,
      //     config,
      //   )
      // } catch (error) {}
      // console.log(3)

      // await signOut(auth)
      toast.info('User is already register, Please login')
    } else {
      toast.error(notificationMessages[error.code])
      dispatch({ type: SIGN_UP_FAIL, payload: error.code })
    }
  }
}

export const googleLogin = () => async () => {
  try {
    const auth = getAuth(app)
    const provider = new GoogleAuthProvider()

    const { user } = await signInWithPopup(auth, provider)
    if (!user.emailVerified) {
      toast.info('Your email is not verified')
      auth().signOut()
    }

    if (user) {
      const { email, uid, displayName } = user
      //create user in DB
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const axiosData = {
        firstname: displayName,
        email,
        uid,
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/social-login`,
        axiosData,
        config,
      )
    }
    toast.success('Login Successfull', {
      autoClose: 500,
    })
  } catch (error) {
    console.log({
      type:"Error in social",
      message: error.message,
      stack: error.stack
    })
  }
}
