import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./pages/App";
import store from "./store";
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    <ToastContainer/>
  </Provider>,
  document.querySelector('#root')
);
