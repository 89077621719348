import {
  PRINT_LABEL_FAIL,
  PRINT_LABEL_SUCCESS,
} from '../PrintLabel/PrintLabelType'
import {
  SAVE_USER_SHIPMENT_DETAILS_FAIL,
  SAVE_USER_SHIPMENT_DETAILS_SUCCESS,
} from '../SaveUserShippmentDetails/SaveUserShipmentDetailsType'
import {
  GET_SINGLE_USER_FAIL,
  GET_SINGLE_USER_SUCCESS,
} from './GetSingleUserType'

const initialState = {
  token: "",
  detail: {},
  error: '',
  shipmentDetails: {},
  printLabelDetails: {},
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state.detail,
        detail: action.payload,
      }
    case SAVE_USER_SHIPMENT_DETAILS_SUCCESS:
      return {
        ...state,
        shipmentDetails: action.payload,
      }
    case PRINT_LABEL_SUCCESS:
      return {
        ...state,
        printLabelDetails: action.payload,
      }
    case GET_SINGLE_USER_FAIL:
    case SAVE_USER_SHIPMENT_DETAILS_FAIL:
    case PRINT_LABEL_FAIL:
      return {
        error: action.message,
      }
    default:
      return state
  }
}
