import {
  CREATE_STORE_DATA_FAIL,
  CREATE_STORE_DATA_SUCCESS,
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_FAIL,
  GET_ALL_ORDERS_RATES_FAIL,
  GET_ALL_ORDERS_RATES_SUCCESS,
  GET_ALL_SELECTED_ORDERS,
  GET_ALL_SELECTED_ORDERS_FAIL,
  GET_ALL_STORE_DATA_FAIL,
  GET_ALL_STORE_DATA_SUCCESS,
} from './StoreType'
const initialState = {
  stores: [],
  error: null,
  orders: [],
  rates: [],
  selectedOrders:[]
}
export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STORE_DATA_SUCCESS:
    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
      }
    case GET_ALL_SELECTED_ORDERS:
      return {
        ...state,
        selectedOrders:action.payload
      }
    case GET_ALL_STORE_DATA_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      }
    case GET_ALL_ORDERS_RATES_SUCCESS:
      return {
        ...state,
        rates: action.payload,
      }
    case GET_ALL_ORDERS_RATES_FAIL:
    case CREATE_STORE_DATA_FAIL:
    case GET_ALL_STORE_DATA_FAIL:
    case GET_ALL_ORDERS_FAIL:
    case GET_ALL_SELECTED_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
