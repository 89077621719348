export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;



export const routes = {
    HOME : "/",
    LOGIN : "/login",
    REGISTER: "/sign-up",
    ADD_STORE: "/add-store",
    USER_PROFILE: "/user-profile",
    ORDERS:"/orders",
    ORDERS_LIST:"/orders-list",
    RATE:"/rate",
    ORDER_RATE:"/orders-rate",
    SHIPMENTDETAILS:"/shipmentdetails",
    PRINT:"/print",
    NO_ORDERS_SELECTED:"/no-order-selected"
}

export const guestRoutes = [routes.LOGIN, routes.REGISTER]

export const notificationMessages = {
  'auth/email-already-in-use': 'Email already exist',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'You have entered a wrong password',
  'auth/weak-password':
    'Password is very week. Please entered a strong password',
  'Firebase: Error (auth/email-already-in-use).':
    'This username is already exist',
  'Request failed with status code 400': 'This username is already exist',
  'auth/invalid-email': 'Please entered a valid email',
  'auth/too-many-requests': 'Please refresh the page and try again',
}

export const userConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("mason_token"),
  },
}