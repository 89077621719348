import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Btn } from '../../components/Controls/Button/Button'
import avatar from '../../assets/images/avatar.jpg'
import { forgetPassword } from '../../store/auth/ForgetPassword/ForgetPasswordAction'
import { updateUserData } from '../../store/auth/UploadProfile/UploadProfileAction'
import { getSingleUser } from '../../store/User/GetSingleUser/GetSingleUserAction'
import { BASE_URL } from '../../utils/Constants'
function Profile() {
  const dispatch = useDispatch()
  const { detail: user } = useSelector((state) => state.User)
  const { message, error } = useSelector((state) => state.Profile)
  const [loader, setLoader] = useState(false)
  const [firstname, setFirstname] = useState(user?.firstname ?? '')
  const [lastname, setLastname] = useState(user?.lastname ?? '')
  const [fileData, setFileData] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        await getSingleUser()
      } catch (error) {}
    }
    fetchData()
  }, [message, error, user, dispatch])

  async function handleChangePassword() {
    await dispatch(forgetPassword(user))
  }

  const fileChangeHandler = (e) => {
    setFileData(e.target.files[0])
  }

  const uploadImageSubmit = async (e) => {
    e.preventDefault()
    try {
      const data = new FormData()

      const imageName = 'user' + new Date().getTime()
      const extension = fileData.name.split('.').reverse()[0]
      const newName = imageName + '.' + extension
      data.append('image', fileData, newName)
      data.append('firstname', firstname)
      data.append('lastname', lastname)

      await dispatch(updateUserData(data))
      await dispatch(getSingleUser())
    } catch (error) {}
  }

  return (
    <div className="container-xl px-4 mt-4">
      <nav className="nav nav-borders">
        <h3>Profile</h3>
      </nav>
      <hr className="mt-0 mb-4" />
      <div className="row">
        <div className="col-xl-8">
          <div className="card mb-4">
            <div className="card-header">User Details</div>
            <div className="card-body">
              <div>
                <form
                  method="POST"
                  encType="multipart/form-data"
                  onSubmit={uploadImageSubmit}
                >
                  <div id="user-profile-image">
                    <img
                      src={`${BASE_URL}/${user?.image}`}
                      onError={({ currentTarget }) => {
                       
                        currentTarget.src = avatar
                      }}
                    />
                  </div>
                  <input
                    className="form-control"
                    name="image"
                    type="file"
                    id="formFile"
                    onChange={fileChangeHandler}
                  />
                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputFirstName">
                        First name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter your first name"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputLastName">
                        Last name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter your last name"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="small mb-1" htmlFor="inputEmailAddress">
                      Email address
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      disabled
                      placeholder="Enter your email address"
                      value={user?.email ?? ''}
                      autoComplete="off"
                    />
                  </div>

                  <div className="sbmt-btn">
                    <Btn
                      icon={<i className="fas fa-angle-right"></i>}
                      load={loader}
                    >
                      Save Change
                    </Btn>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card mb-4 mb-xl-0">
            <div className="card-header">Update password</div>
          </div>
          <div className="card-body text-center">
            <div className="sbmt-btn">
              <Btn
                icon={<i className="fas fa-angle-right"></i>}
                handleClick={handleChangePassword}
              >
                Change Password
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
