import React, { useEffect } from 'react'
import './rate.css'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Loader from '../../components/Loader/Loader'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getAllOrdersRate } from '../../store/store/CreateStoreData/StoreActions'
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    priority: {
      fontSize: '18px',
      fontWeight: '600',
      color: '#000',
    },
    title: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#000',
    },
    paper: {
      padding: '16px 0',
      color: theme.palette.text.secondary,
      background: '#F5F5F5',
      border: '1px solid #C7C7C7',
      borderRadius: '4px',
      boxShadow: 'none',
    },
  }),
)

const Rate = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { rates } = useSelector((state) => state.StoreData)
  const { id } = useParams();

  useEffect(async () => {
    if(id){
      try {
        await dispatch(getAllOrdersRate(id))
      } catch (error) {}
    }
  }, [id])

  return (
    <>
      {/* // Sidebar and header starts ends here */}
      <main className="content">
        <div className="rate-main" id="rate-main-wrapper">
          {/* <div className="rate-btn">
            <Button
              variant="contained"
              color="primary"
              href="#"
              style={{
                background: ' #806BE5',
                borderRadius: '10px',
                padding: '10px 30px',
                color: '#fff',
              }}
            >
              Get Rate Quote
            </Button>
          </div> */}

          <Grid container spacing={3}>
            {rates?.length > 0 ? (
              rates.map((rate, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Box style={{ padding: '0 15px' }}>
                      <Typography>
                        <h3 className={classes.priority}>
                          {rate?.carrier_friendly_name}
                        </h3>
                      </Typography>
                      <Typography>
                        <h3 className={classes.title}>
                          Estimated delivery in {rate?.carrier_delivery_days}
                          {rate?.carrier_delivery_days?.length > 3
                            ? ''
                            : ' Day'}
                        </h3>
                        <p className="delivery-tx">
                          Free Tracking·${rate?.insurance_amount?.amount}
                          insurance included
                        </p>
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: '#DAF1D5',
                        border: '1px solid #C7C7C7',
                        marginBottom: '15px',
                        padding: '10px 15px',
                      }}
                    >
                      <Typography>
                        <h3 className="price-title">
                          ${rate?.shipping_amount?.amount}
                        </h3>
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        padding: '0 15px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        component={Link}
                        to={`/shipmentdetails?detailId=${id}&index=${index}`}
                        variant="contained"
                        color="primary"
                        style={{
                          background: ' #806BE5',
                          borderRadius: '10px',
                          padding: '8px 25px',
                          color: '#fff',
                          textTransform: 'capitalize',
                        }}
                      >
                        Ship Now
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Loader />
            )}
          </Grid>
        </div>
      </main>
    </>
  )
}

export default Rate
