import { CLEAR_ERRORS, LOGIN_FAIL, LOGIN_SUCCESS } from "./LoginType";
import { LOGOUT_SUCCESS, LOGOUT_FAIL } from "../Logout/LogoutType";
import { SIGN_UP_FAIL, SIGN_UP_SUCCESS } from "../Signup/SignupType";
import {
  UPLOAD_PROFILE_FAIL,
  UPLOAD_PROFILE_SUCCESS,
} from "../UploadProfile/UploadProfileType";

const initialState = { user: {}, isAuthenticated: false };

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userCreated: false,
        error: null,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        message:action.payload
      };
    case SIGN_UP_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userCreated: false,
        error: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userCreated: false,

        error: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        userCreated: false,
        user: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        userCreated: false,
        error: action.payload,
      };
    case UPLOAD_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          image: action.payload,
        },
      };
    case UPLOAD_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
