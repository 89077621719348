
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';


export const VERIFY_MAIL_SUCCESS = 'VERIFY_MAIL_SUCCESS';
export const VERIFY_MAIL_FAIL = 'VERIFY_MAIL_FAIL';


export const CLEAR_ERRORS = "CLEAR_ERRORS"

