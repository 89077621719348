import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import orderMix from '../../assets/images/ordermix.png'
import connect from '../../assets/images/connect.png'
import order from '../../assets/images/order.png'
import speed from '../../assets/images/speed.png'
import Header from '../Header/Header'
import { routes } from '../../utils/Constants'

function Layout({ children }) {
  const {pathname} = useLocation()

  return (
    <>
      <div className="main-body">
        <div className="layout has-sidebar fixed-sidebar fixed-header">
          <aside id="sidebar" className="sidebar break-point-lg has-bg-image">
            <div className="sidebar-layout">
              <div className="sidebar-header">
                <span>
                  <img src={orderMix} />
                </span>
              </div>
              <div className="sidebar-content">
                <nav className="menu open-current-submenu">
                  <ul>
                    <li className={`menu-item  ${pathname === routes.HOME ? 'active':""}`} >
                      <Link to="/">
                        <span className="menu-icon">
                          <img src={connect} />
                        </span>
                        <span className="menu-title">Connect Store</span>
                      </Link>
                    </li>
                    <li  className={`menu-item  ${pathname === routes.ORDERS_LIST ? 'active':""}`}>
                      <Link to="/orders-list">
                        <span className="menu-icon">
                          <img src={order} />
                        </span>
                        <span className="menu-title">Orders</span>
                      </Link>
                    </li>
                    <li  className={`menu-item  ${pathname === routes.ORDER_RATE  ? 'active':""}`}>
                      <Link to="/orders-rate">
                        <span className="menu-icon">
                          <img src={speed} />
                        </span>
                        <span className="menu-title">Rate</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </aside>
        </div>
      
        <Header>{children}</Header>
      </div>
    </>
  )
}

export default Layout
