import {
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
} from './ForgetPasswordType'

const initialState = {
  message: '',
  error: null,
}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.payload,
      }

    case FORGET_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
