import axios from 'axios'
import { toast } from 'react-toastify'
import { userConfig as config } from '../../../utils/Constants'
import {
  CREATE_STORE_DATA_FAIL,
  CREATE_STORE_DATA_SUCCESS,
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_FAIL,
  GET_ALL_STORE_DATA_FAIL,
  GET_ALL_STORE_DATA_SUCCESS,
  GET_ALL_ORDERS_RATES_SUCCESS,
  GET_ALL_ORDERS_RATES_FAIL,
  GET_ALL_SELECTED_ORDERS,
  GET_ALL_SELECTED_ORDERS_FAIL,
} from './StoreType'


export const createStoreData = (url) => async (dispatch) => {
  try {
    const values = {
      url,
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/store/createStore`,
      values,
    )

    dispatch({ type: CREATE_STORE_DATA_SUCCESS, payload: data })
    window.location.href = data.url
  } catch (error) {
    if (error?.response?.data?.message === 'Your store is already connected') {
      return toast.info(error.response.data.message)
    }

    dispatch({ type: CREATE_STORE_DATA_FAIL, payload: error })
  }
}

export const getAllStoreData = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/store/`,
    )
    dispatch({ type: GET_ALL_STORE_DATA_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: GET_ALL_STORE_DATA_FAIL, payload: error })
  }
}

export const deleteStore = (url) => async (dispatch) => {
  try {

    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/v1/store/${url}`,
    )
    toast.success('Store deleted successfully')
  } catch (error) {}
}

export const getAllOrders = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/store/orders`,
    )
    dispatch({ type: GET_ALL_ORDERS, payload: data?.ordersList })
  } catch (error) {
    dispatch({ type: GET_ALL_ORDERS_FAIL, payload: error })
  }
}

export const getSelectedOrdersDetails = () => async (dispatch) => {
  const orderId = localStorage.getItem('select')
  const splitedId = orderId.split(',')

  try {
    const bodyData = {
      splitedId,
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/store/selectedOrders`,
      bodyData,
    )
    dispatch({ type: GET_ALL_SELECTED_ORDERS, payload: data?.orders })
  } catch (error) {
    dispatch({ type: GET_ALL_SELECTED_ORDERS_FAIL, payload: 'Not order found' })
  }
}

export const getAllOrdersRate = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/store/orders/rate/${id}`,
    )
    dispatch({ type: GET_ALL_ORDERS_RATES_SUCCESS, payload: data?.data })
  } catch (error) {
    toast.error('Please fill the correct data')
    dispatch({ type: GET_ALL_ORDERS_RATES_FAIL, payload: 'Rate not found' })
  }
}
