import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import Login from "../pages/LoginPage/Login";
import Home from "../pages/Home/Home";
import Signup from "../pages/SignUpPage/Signup";
import { guestRoutes, routes } from "../utils/Constants";
import Profile from "../pages/ProfilePage/Profile";
import Layout from "../components/Layout/Layout";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import app from "../fire";
import axios from "axios";
import OrderRate from "../pages/OrdersRate/OrdersRate";
import Rate from "../pages/Rate/Rate";
import ShipmentDetails from "../pages/ShipmentDetails/ShipmentDetails";
import OrdersList from "../pages/OrdersList/OrdersList";
import Loader from "../components/Loader/Loader";
import NoOrderIsSelected from "../pages/NoOrderIsSelected/NoOrderIsSelected";
import PrintLabel from "../pages/PrintLabel/PrintLabel";

export default function Routes() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(async () => {
    setLoading(true);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user.auth.currentUser.emailVerified) {
          signOut(auth);
          

          history.push("/login");
        } else {
          localStorage.setItem("mason_token", user.accessToken);
          axios.interceptors.request.use((config)=>{
            config.headers.authorization = `Bearer ${user.accessToken}`;
            config.headers.Accept = "application/json";
            return config;
          });
          if (guestRoutes.includes(pathname)) {
            history.push("/");
          }
        }
      } else {
        if (!guestRoutes.includes(pathname)) {
          history.push("/login");
        }
      }
      setLoading(false);
    });
  }, []);

  function RouteWithLayout({ layout, component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          React.createElement(
            layout,
            props,
            React.createElement(component, props)
          )
        }
      />
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Switch>
          <Route exact path={routes.REGISTER} component={Signup} />
          <Route exact path={routes.LOGIN} component={Login} />

          <RouteWithLayout
            exact
            path={routes.HOME}
            layout={Layout}
            component={Home}
          />

          <RouteWithLayout
            exact
            layout={Layout}
            path={routes.USER_PROFILE}
            component={Profile}
          />

          <RouteWithLayout
            exact
            path={routes.ORDERS_LIST}
            layout={Layout}
            component={OrdersList}
          />
          <RouteWithLayout
            exact
            path={routes.ORDER_RATE}
            layout={Layout}
            component={OrderRate}
          />
          <RouteWithLayout
            exact
            path="/rate/:id"
            layout={Layout}
            component={Rate}
          />
          <RouteWithLayout
            exact
            path={routes.SHIPMENTDETAILS}
            layout={Layout}
            component={ShipmentDetails}
          />
          <RouteWithLayout
            exact
            path={routes.PRINT}
            layout={Layout}
            component={PrintLabel}
          />
          <RouteWithLayout
            exact
            path={routes.NO_ORDERS_SELECTED}
            layout={Layout}
            component={NoOrderIsSelected}
          />
        </Switch>
      )}
    </>
  );
}
