import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import {toast} from 'react-toastify'
import app from '../../../fire'
import {
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
} from './ForgetPasswordType'

export const forgetPassword = (data) => async (dispatch) => {
  const { email } = data

  const auth = getAuth(app)

  sendPasswordResetEmail(auth, email ? email : data)
    .then(() => {
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: 'Password Reset Email Sent Successfully',
      })
      toast.success("Password Reset Email Sent Successfully", {
        autoClose: 500,
      })
    })
    .catch((error) => {
      dispatch({ type: FORGET_PASSWORD_FAIL, payload: error.code })
    })
}
