import React from 'react'

export const Btn = ({ handleClick, btnClass, icon, load, children, type }) => {
  return (
    <>
      <button
        className={btnClass ? btnClass : 'default-btn'}
        type={type ? type : "submit"}
        onClick={handleClick}
        disabled={load}
      >
        {load ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : (
          <>
            {children}
            {icon && icon !== '' ? icon : ''}
          </>
        )}
      </button>
    </>
  )
}
