import axios from "axios";
import {
  UPLOAD_PROFILE_FAIL,
  UPLOAD_PROFILE_SUCCESS,
} from "./UploadProfileType";
import {toast } from 'react-toastify'

export const updateUserData = (file) => async (dispatch) => {

  try {
    const api = `${process.env.REACT_APP_BASE_URL}/v1/users/update`;
    let res = await axios.post(api, file);
    toast.success("Profile Update Successfully", {
      autoClose: 500,
    })
    dispatch({
      type: UPLOAD_PROFILE_SUCCESS,
      payload: res?.data,
    });

  } catch (error) {
    toast.error("Profile Update Fail")
    dispatch({
      type: UPLOAD_PROFILE_FAIL,
      payload: error?.data?.message,
    });
  }

}
