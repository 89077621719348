import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { printLabel } from "../../store/User/GetSingleUser/GetSingleUserAction";
import Loader from "../../components/Loader/Loader";
import { Btn } from "../../components/Controls/Button/Button";
import { useLocation, useParams } from "react-router-dom";
import { getAllOrdersRate } from "../../store/store/CreateStoreData/StoreActions";
import axios from "axios";
import { userConfig } from "../../utils/Constants";
import { toast } from "react-toastify";
import { PRINT_LABEL_SUCCESS } from "../../store/User/PrintLabel/PrintLabelType";

export default function PrintLabel() {
  const dispatch = useDispatch();
  const { printLabelDetails } = useSelector((state) => state.User);
  const { rates } = useSelector((state) => state.StoreData);
  const { search } = useLocation();
  const url = new URLSearchParams(search);
  const index = url.get("index");
  const detailId = url.get("detailId");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const print = async (rates, index) => {
    await dispatch(printLabel(rates[index], detailId));
  };

  const load = async (detailId) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/store/orders/rate/${detailId}`,
    );
    const rates = data?.data ?? [];
    if (rates.length > 0 && index) {
      print(rates, index);
    }
  };

  useEffect(() => {
    if (index !== undefined || (index !== null && detailId)) {
      try {
        load(detailId);
      } catch (error) {}
    }
  }, [detailId]);

  const printLabel = (data, detailId) => async () => {
    const { service_code } = data;
    try {
      // const orderId = localStorage.getItem("select");
      // const splitedId = orderId.split(",");

      const userData = {
        splitedId: ["qweqwe"],
        label_format: "png",
        label_layout: "4x6",
        label_download_type: "inline",
        shipment: {
          service_code,
          ship_from: {
            name: "John Doe",
            company_name: "Example Corp.",
            address_line1: "4009 Marathon Blvd",
            city_locality: "Austin",
            state_province: "TX",
            postal_code: "78756",
            country_code: "US",
            phone: "512-555-5555",
          },
          ship_to: {
            name: "Amanda Miller",
            address_line1: "525 S Winchester Blvd",
            city_locality: "San Jose",
            state_province: "CA",
            postal_code: "95128",
            country_code: "US",
          },
          packages: [
            {
              weight: {
                value: 17,
                unit: "pound",
              },
              dimensions: {
                length: 36,
                width: 12,
                height: 24,
                unit: "inch",
              },
            },
          ],
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/v1/store/print/${detailId}`;
      const { data } = await axios.post(url, userData);
      dispatch({ type: PRINT_LABEL_SUCCESS, payload: data?.data });
    } catch (error) {
      const message = error?.response?.data?.message ?? "Internal server error";
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : printLabelDetails ? (
        <div className="d-flex justify-content-center">
          <iframe
            src={`${printLabelDetails?.label_download?.href}`}
            width="100%"
            height="500px"
          ></iframe>
        </div>
      ) : (
        <div>
          <h4 className="m-4 text-center">Something went wrong</h4>
          <p className="text-center">{error}</p>
        </div>
      )}
    </>
  );
}
