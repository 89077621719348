import { LOGOUT_SUCCESS, LOGOUT_FAIL } from './LogoutType'
import { toast } from 'react-toastify'
import { getAuth } from 'firebase/auth'
import app from '../../../fire'

export const logout = () => async (dispatch) => {
  try {
    const auth = getAuth(app)
    await auth.signOut()
    localStorage.removeItem('mason_token')
    localStorage.removeItem('select')

    toast.info('User Logout Successfully')
    dispatch({ type: LOGOUT_SUCCESS })
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error })
  }
}
