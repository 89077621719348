import React from 'react'
import {Link} from 'react-router-dom'
import no_orders from '../../assets/images/no_orders.gif'

function NoOrderIsSelected() {
  return (
    <>
      <div className="container-fluid mb-100">
        <div className="row">
          <div className="col-md-12">
            <div className="card  border mt-2">
              <div className="card-body cart">
                <div className="col-sm-12 empty-cart-cls text-center">
                  <img
                    src={no_orders}
                    width="300"
                    height="300"
                    className="img-fluid mb-4 mr-3"
                  />
                  <h3>
                    <strong>No order is selected</strong>
                  </h3>
                  <h4>Add alteast one order to get rate!</h4>
                  <Link
                    to="/orders-list"
                    className="btn btn-lg btn-primary cart-btn-transform m-3 "
                    data-abc="true"
                  >
                    SELECT ORDERS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoOrderIsSelected
