import { GET_ALL_PRODUCT_FAIL, GET_ALL_PRODUCT_SUCCESS } from './ProductsType'

export default (state = { products: {} }, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case GET_ALL_PRODUCT_FAIL:
      return {
        error: action.payload,
      }
    default:
      return state
  }
}
