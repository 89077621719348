import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { googleLogin, login } from "../../store/auth/Login/LoginAction";
import { Btn } from "../../components/Controls/Button/Button";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import img from "../../assets/images/content.png";
import logo from "../../assets/images/logo.png";
import { forgetPassword } from "../../store/auth/ForgetPassword/ForgetPasswordAction";
function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, error } = useSelector((state) => state.LoginUser);
  const [loader, setLoader] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (isAuthenticated === true) {
      history.push("/");
    }
  }, [error, isAuthenticated]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email"),
    password: yup.string().required("Password is required"),
  });

  const handleLoginSubmit = async (values) => {
    try {
      setLoader(true);

      await dispatch(login(values));
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const forgotPasswordSchema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email"),
  });

  const forgetPasswordSubmit = async (values) => {
    try {
      await dispatch(forgetPassword(values));
    } catch (error) {}
  };

  const viewPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await dispatch(googleLogin());
      history.push("/");
    } catch (error) {}
  };
  return (
    <>
      <div className="login-body">
        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: "", password: "" }}
          onSubmit={(values) => {
            handleLoginSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <section className="login-first-section">
              <Form>
                <div className="container">
                  <div className="cstm-logo">
                    <Link to="/login">
                      {" "}
                      <img src={logo} />
                    </Link>
                  </div>
                  <div className="login-top-img">
                    <img src={img} />
                  </div>
                  <div className="row login-rw">
                    <div className="login-form error">
                      <h2 className="main-heading">Login</h2>
                      <p>
                        Don't have an account?{" "}
                        <Link to="/sign-up" className="register-btn">
                          Register
                        </Link>
                      </p>
                      <div className="login-error-form">
                        <div className="login-form-er">
                          <div className="form-field">
                            <label className="email">Email Address</label>
                            <br />
                            <div className="icon-field">
                              <Field
                                className={`mail-in ${
                                  errors.email && touched.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="text"
                                placeholder="Email Address"
                                name="email"
                                required=""
                                autoComplete="off"
                              />
                              <i className="far fa-envelope"></i>
                            </div>

                            <ErrorMessage name="email">
                              {(msg) => (
                                <div>
                                  {msg ? (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>
                                      {msg}
                                    </p>
                                  ) : (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>
                                      Oops! The email you have entered is
                                      incorrect.
                                    </p>
                                  )}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="form-field">
                            <label className="pass">Password</label>
                            <br />
                            <div className="icon-field">
                              <Field
                                className={`passwrd ${
                                  errors.password && touched.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type={passwordType}
                                placeholder="Password"
                                name="password"
                                required=""
                                autoComplete="off"
                              />
                              <i className="fas fa-lock"></i>
                              <div className="eye-icon">
                                <i
                                  className="far fa-eye"
                                  id="togglePassword"
                                  onClick={viewPassword}
                                ></i>
                              </div>
                            </div>
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div>
                                  {msg ? (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>
                                      {msg}
                                    </p>
                                  ) : (
                                    <p className="login-error invalid">
                                      <i className="fas fa-exclamation-circle"></i>
                                      Oops! The password you have entered is
                                      incorrect.
                                    </p>
                                  )}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="pass-remember">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                id="remember"
                                name="remember"
                                value="Remember Me"
                              />
                              <span>Remember Me</span>
                            </label>
                            <div className="forgot">
                              <button
                                type="button"
                                className="psw"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal"
                              >
                                Forgot Password?
                              </button>
                            </div>
                          </div>
                          <div className="submit-btn">
                            <Btn
                              icon={<i className="fas fa-angle-right"></i>}
                              load={loader}
                            >
                              Sign In
                            </Btn>
                          </div>
                          <div className="google-button mt-2">
                            <button
                              type="button"
                              className="btn btn-lg btn-google btn-block btn-outline"
                              onClick={handleGoogleLogin}
                            >
                              <img
                                id="google-login-img"
                                src="https://img.icons8.com/color/16/000000/google-logo.png"
                              />
                              <span style={{ fontWeight: 500 }}>
                                SignIn Using Google
                              </span>
                            </button>
                          </div>
                        </div>
                        {/* <div className="row my-2 d-flex justify-content-center">
                          <div className=" border col-md-11 d-flex justify-content-center">
                            <Btn
                              type="button"
                              btnClass=" btn btn-lg btn-google btn-block btn-outline"
                              handleClick={handleGoogleLogin}
                            >
                              <>
                                SignIn Using Google
                              </>
                            </Btn>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="login-right-img">
                    <img src={img} />
                  </div>
                </div>
              </Form>
            </section>
          )}
        </Formik>

        {/* <!-- Modal --> */}
        <Formik
          validationSchema={forgotPasswordSchema}
          initialValues={{ email: "" }}
          onSubmit={(values) => {
            forgetPasswordSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div
                className="modal fade"
                id="myModal"
                tableindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header custom">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h3 className="modal-title" id="exampleModalLabel">
                        Reset Password
                      </h3>
                      <p>
                        Enter the email associated with your account and we'll
                        send an email with instructions to reset your password.
                      </p>
                    </div>
                    <div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-12 col-lg-12 col-md-12">
                            <div className="form-field">
                              <label>Email Address</label>
                              <br />
                              <div className="icon-field">
                                <Field
                                  className={`mail-in ${
                                    errors.email && touched.email
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="text"
                                  placeholder="Email Address"
                                  name="email"
                                  required=""
                                />
                                <i className="far fa-envelope"></i>
                              </div>
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div>
                                    {msg ? (
                                      <p className="login-error invalid">
                                        <i className="fas fa-exclamation-circle"></i>
                                        {msg}
                                      </p>
                                    ) : (
                                      <p className="login-error invalid">
                                        <i className="fas fa-exclamation-circle"></i>
                                        Oops The email you have entered is
                                        incorrect.
                                      </p>
                                    )}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <Btn btnClass={"btn dark-btn"} id="first">
                          <i className="fas fa-angle-right"></i>
                        </Btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* <!-- Modal close ---> */}
      </div>
    </>
  );
}

export default Login;
