import React, { useEffect, useState } from "react";
import "./shipmentDetails.css";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Loader from "../../components/Loader/Loader";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllOrdersRate } from "../../store/store/CreateStoreData/StoreActions";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    priority: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    title: {
      fontSize: "14px",
      fontWeight: "600",
      color: "#000",
    },
    heading: {
      fontSize: "16px",
      fontWeight: "600",
      color: "#000",
    },
    summary: {
      background: "linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%)",
      border: "1px solid #C7C7C7",
    },

    cost: {
      background: "#EDEDED",
      border: "1px solid #C7C7C7",
    },

    shipCost: {
      background: "#F5F5F5",
      border: "1px solid #C7C7C7",
    },
    paper: {
      padding: "16px 0",
      color: theme.palette.text.secondary,
      background: "#F5F5F5",
      border: "1px solid #C7C7C7",
      borderRadius: "4px",
      boxShadow: "none",
    },
  })
);

const ShipmentDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const url = new URLSearchParams(search);
  const index = url.get("index");
  const detailId = url.get("detailId");
  const { rates, error } = useSelector((state) => state.StoreData);

  useEffect(async () => {
    console.log(detailId, index);

    if (index !== undefined && index !== null && detailId) {
      try {
        await dispatch(getAllOrdersRate(detailId));
      } catch (error) {}
    }
  }, [detailId, index]);

  if (error === "Rate not found") {
    history.push("/no-order-selected");
  }
  return (
    <>
      <main className="content">
        {rates[index] ? (
          <div className="shipdetails-main" id="shipdetails-main-wrapper">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Box
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <h3
                      className={classes.heading}
                      style={{ paddingTop: "15px", paddingBottom: "6px" }}
                    >
                      Choose a Service
                    </h3>
                  </Typography>
                </Box>
                <Accordion className={classes.summary}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="retail-box">
                      <h3 className={classes.heading} style={{ margin: "0" }}>
                        First Class Package
                      </h3>
                      <p className="retail-tx">
                        ${`${rates[index]?.other_amount?.amount}`} retail
                      </p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexFlow: "column" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <p className="delivery-tx shipment">
                          <span className="green-bg">CHEAPEST</span>Estimated
                          delivery in {`${rates[index]?.carrier_delivery_days}`}
                          {rates[index]?.carrier_delivery_days?.length > 3
                            ? ""
                            : " Day"}
                        </p>
                      </Typography>
                      <Typography className={classes.heading}>
                        <h3 className="ship-title">{`$${rates[index]?.shipping_amount?.amount}`}</h3>
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <p className="delivery-tx shipment"></p>
                      </Typography>
                      <Typography>
                        <p className="delivery-tx shipment">average</p>
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box
                  className={classes.shipCost}
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 14px 2px 14px",
                  }}
                >
                  <Typography>
                    <h3 className={classes.title}>2 labels</h3>
                  </Typography>
                  <Typography>
                    <h3 className={classes.title}>average</h3>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography>
                  <h3
                    className={classes.heading}
                    style={{ paddingBottom: "6px" }}
                  >
                    Finish purchase
                  </h3>
                </Typography>
                <Box
                  className={classes.shipCost}
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 14px 2px 14px",
                  }}
                >
                  <Typography>
                    <h3 className={classes.title}>Ship Date</h3>
                  </Typography>
                  <Typography>
                    <h3 className={classes.title}>
                      {moment(`${rates[index]?.ship_date}`, [
                        `YYYY-MM-DD`,
                      ]).format(`Do MMMM YYYY`)}
                    </h3>
                  </Typography>
                </Box>
                <Box
                  className={classes.cost}
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 14px 2px 14px",
                  }}
                >
                  <Typography>
                    <h3 className={classes.title}>Total Cost</h3>
                  </Typography>
                  <Typography>
                    <h3 className={classes.title}>
                      {`$${rates[index]?.shipping_amount.amount}`}
                    </h3>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  color="primary"
                  onClick={() => history.push(`/rate/${detailId}`)}
                  style={{ color: "#5DACF4", textTransform: "capitalize" }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
            <div className="details-btn">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/print?detailId=${detailId}&index=${index}`}
                style={{
                  background: " #806BE5",
                  borderRadius: "10px",
                  padding: "10px 30px",
                  color: "#fff",
                }}
              >
                Buy Labels
              </Button>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};

export default ShipmentDetails;
